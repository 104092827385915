<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="white"
      elevation="1"
      height="80"
    >
      <base-card
        :to="{ name: 'Home' }"
        class="mt-4"
      >
        <base-img
          :src="require('@/assets/sod-log-64.png')"
          class="mr-3 hidden-xs-only"
          contain
          max-width="64"
          width="100%"
        />
      </base-card>
      <base-card
        :to="{ name: 'Home' }"
        class="mt-4"
      >
        <base-img
          :src="require('@/assets/logo-text.png')"
          contain
          max-width="200"
          width="100%"
        />
      </base-card>

      <div v-if="isBeta">
        <h5
          class="text-uppercase font-weight-bold text-center"
          color="grey lighten-2"
        >
          BETA
        </h5>
      </div>

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>
      <base-btn
        text
        href="https://main.scrumteamondemand.com/authentication/login"
        target="_blank"
        :ripple="false"
        class="pa-1 hidden-sm-and-down"
        height="auto"
        width="100"
        min-width="100"
        rel="noopener"
      >
        Login
      </base-btn>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',
    components: {
      HomeDrawer: () => import('./Drawer'),
    },
    data: () => ({
      isBeta: false,
      drawer: null,
      items: [
        'Home',
        'Discover',
        'About Us',
        'Communities',
        'News',
        'Contact',
      ],
    }),
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
